<template>
    <div class="core-service-page">
        <div class="lv-basic-card lv-mb-72">
            <easy-news-item>
                <template v-slot:image>
                    <img class="core-service-new-image" src="@/assets/images/coreService/image_core01@2x.png" />
                </template>
                <template v-slot:content>
                    <div class="lv-mb-24 lv-mb-24"></div>
                    <easy-title class="lv-mb-24">
                        Case Referral
                    </easy-title>
                    <div class="lv-article-text">
                        Given that a large number of cross-boarder cases would converge on Lawback platform Lawback from
                        time to time refer potential clients to the partners. We honor fast, efficient and professional work. Therefore,
                        cooperating with senior and reliable international law firms worldwide, Lawback could provide clients with
                        best possible legal services all over the world. Our external partners may also find the most suitable lawyers
                        on Lawback platform to achieve global collaboration. The cases may involving in international debt
                        recovery, cross-border investment or trade, intellectual property, M&A, family law, criminal law.etc.
                    </div>
                </template>
            </easy-news-item>
        </div>
        <div class="lv-basic-card lv-mb-72">
            <easy-news-item :is-reverse="true" >
                <template v-slot:image>
                    <img class="core-service-new-image" src="@/assets/images/coreService/image_core02@2x.png" />
                </template>
                <template v-slot:content>
                    <div class="lv-mb-24 lv-mb-24"></div>
                    <easy-title class="lv-mb-24">
                        EASY CONTRACT
                    </easy-title>
                    <div class="lv-article-text lv-mb-24">
                        <strong>
                            Fastly draft a professional international trade procurement contract or purchase contract
                            with EASY CONTRACT by inputting some essential information.
                        </strong>
                    </div>
                    <div class="lv-article-text">
                        Based on nearly 10 years transaction data in international market, Lawback has integrated professional
                        acknowledge and senior international lawyers' experience and has developed EASY CONTRACT to assist
                        international trade practitioner independently draft international trade procurement contract or purchase
                        contract. It consists of risk assessment and comprehensive articles to protect parties' legal right.
                    </div>
                </template>
            </easy-news-item>
        </div>
        <div class="lv-basic-card lv-mb-72">
            <easy-news-item>
                <template v-slot:image>
                    <img class="core-service-new-image" src="@/assets/images/coreService/image_core03@2x.png" />
                </template>
                <template v-slot:content>
                    <easy-title class="lv-mb-24">
                        FTA Searching Tool
                    </easy-title>
                    <div class="lv-article-text lv-mb-24">
                        <strong>
                            Searching tariff rate of Free Trade Agreements between 25 countries and China, and simply
                            applying for Certificate of Orgin.
                        </strong>
                    </div>
                    <div class="lv-article-text">
                        A free trade agreement is a pact between two or more nations to reduce barriers to imports and exports
                        among them. Under a free trade policy, goods and services can be bought and sold across international
                        borders with little or no government tariffs, quotas, subsidies, or prohibitions to inhibit their exchange. China
                        has signed 17 Free Trade Agreements involving in 25 countries and regions so far. With FTA SARCHING
                        TOOL, users can easily seach FTA prefrence and apply for certificate of origin if their products are qualified
                        for the preference rules on Lawback.
                    </div>
                </template>
            </easy-news-item>
        </div>
        <div class="lv-basic-card lv-mb-72">
            <easy-news-item :is-reverse="true" >
                <template v-slot:image>
                    <img class="core-service-new-image" src="@/assets/images/coreService/image_core04@2x.png" />
                </template>
                <template v-slot:content>
                    <div class="lv-mb-24 lv-mb-24"></div>
                    <easy-title class="lv-mb-24">
                        Lawback School
                    </easy-title>
                    <div class="lv-article-text lv-mb-24">
                        Lawback School is an online training school focusing on foreign-related law courses,
                        featuring providing abundant professional legal study resources for students, lawyers,
                        legal specialists and other legal practitioners. It selects internal and external  legal authority,
                        legal affairs principals from large enterprises or multinational corporations,
                        and famous senior associates all over the world to be the lecturers,
                        providing a series of legal training courses in various areas such as international trade,
                        international investment and financing, compliance and legal English.
                    </div>
                </template>
            </easy-news-item>
        </div>
    </div>
</template>

<script>
    import EasyNewsItem from "../../components/EasyNewsItem/EasyNewsItem";
    import EasyTitle from "../../components/EasyTitle/EasyTitle";
    export default {
        name: "coreService",
        components: {EasyTitle, EasyNewsItem}
    }
</script>

<style scoped>
.core-service-page{
    padding-top: 80px;
}
</style>